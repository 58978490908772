<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/ingressos'
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/ingressos')"
              >Ingressos</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Requisitos</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Ingresso
                  <span>{{ fastTrilhaIngresso.descricao_trilha }}</span>
                </h1>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                    <h3 class="aluno_font_color">
                      Requisito
                      <span>{{ fastIngressoRequisito.sequencia }} - {{ fastIngressoRequisito.descricao_atividade }}</span>
                    </h3>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 mt-4 text-right">
                    <button
                      class="btn btn-danger btn-sm mt-2 ml-2"
                      @click="showModal('modalExcluirRequisito')"
                    >
                      <small>Excluir requisito</small>
                    </button>
                    <button
                      class="btn btn-primary mt-2 ml-2 mb-2"
                      @click.prevent="showModal('modalCriarRequisito')"
                    >
                      <small>+ &nbsp; Adicionar requisito</small>
                    </button>
                  </div>
                </div>
                <div class="row mt-2 mb-4">
                  <div class="col-12">
                    <img :src="require('@/assets/images/form-effect-01.png')">
                  </div>
                </div>
              </section>
              <!-- /fd-app-welcome -->
              <!-- Lista de requisitos do ingresso -->
              <div class="row">
                <div class="col-12">
                  <vue-step
                    :now-step="nowStep"
                    :step-list="stepList"
                    @selected="(item) => selecionaRequisitoStep(item)"
                  />
                </div>                
              </div>
              <!-- /Lista de requisitos do ingresso -->
              <!-- Configuração do requisito -->
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="h6 mt-4 aluno_font_color">Descrição do requisito</label>
                    <input
                      v-model="fastIngressoRequisito.descricao_atividade"
                      type="text"
                      class="form-control"
                      @keyup.prevent="fastIngressoRequisito.alteracao_pendente = true"
                    >
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <label class="h6 mt-4 aluno_font_color">Data início</label>
                  <div class="form-group">
                    <input
                      v-model="fastIngressoRequisito.data_inicio"
                      class="form-control"
                      type="date"
                      @change.prevent="fastIngressoRequisito.alteracao_pendente = true"
                    >
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <label class="h6 mt-4 aluno_font_color">Data fim</label>
                  <div class="form-group">
                    <input
                      v-model="fastIngressoRequisito.data_fim"
                      class="form-control"
                      type="date"
                      @change.prevent="fastIngressoRequisito.alteracao_pendente = true"
                    >
                  </div>
                </div>
                <div
                  v-if="fastIngressoRequisito.alteracao_pendente"
                  class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                >
                  <button
                    class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                    @click.prevent="atualizaRequisito()"
                  >
                    <small>
                      <i
                        class="fa fa-floppy-o"
                        aria-hidden="true"
                      />
                      &nbsp;Salvar alterações
                    </small>
                  </button>
                  <button
                    class="btn btn-secondary btn-sm mt-2 ml-2"
                    @click.prevent="getTrilhaIngressos()"
                  >
                    <small>
                      <i
                        class="fa fa-ban"
                        aria-hidden="true"
                      />
                      &nbsp;Cancelar
                    </small>
                  </button>
                </div>
              </div>
              <!-- /Configuração do requisito -->

              <!-- Requisito inscrição -->
              <div
                v-if="fastIngressoRequisito.etapa == 'IA'"
                class="row"
              >
                <div class="col-sm-12 col-md-12 col-lg-2 text-center">
                  <label class="h6 mt-4 aluno_font_color">Ano de conclusão</label>
                  <div class="fd-app-meus-cursos mt-0">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                        <label class="switch">
                          <input
                            v-if="fastRequisitoInscricao.ano_conclusao"
                            type="checkbox"
                            checked="checked"
                            @click="chkRequisito('ano_conclusao', fastRequisitoInscricao.ano_conclusao)"
                          >
                          <input
                            v-else
                            type="checkbox"
                            @click="chkRequisito('ano_conclusao', fastRequisitoInscricao.ano_conclusao)"
                          >
                          <span class="slider round" />
                        </label>                             
                      </div>
                    </div>
                  </div>
                </div>  

                <div class="col-sm-12 col-md-12 col-lg-2 text-center">
                  <label class="h6 mt-4 aluno_font_color">ENEM</label>
                  <div class="fd-app-meus-cursos mt-0">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                        <label class="switch">
                          <input
                            v-if="fastRequisitoInscricao.utilizar_enem"
                            type="checkbox"
                            checked="checked"
                            @click="chkRequisito('utilizar_enem', fastRequisitoInscricao.utilizar_enem)"
                          >
                          <input
                            v-else
                            type="checkbox"
                            @click="chkRequisito('utilizar_enem', fastRequisitoInscricao.utilizar_enem)"
                          >
                          <span class="slider round" />
                        </label>                             
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-2 text-center">
                  <label class="h6 mt-4 aluno_font_color">PCD</label>
                  <div class="fd-app-meus-cursos mt-0">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                        <label class="switch">
                          <input
                            v-if="fastRequisitoInscricao.pcd"
                            type="checkbox"
                            checked="checked"
                            @click="chkRequisito('pcd', fastRequisitoInscricao.pcd)"
                          >
                          <input
                            v-else
                            type="checkbox"
                            @click="chkRequisito('pcd', fastRequisitoInscricao.pcd)"
                          >
                          <span class="slider round" />
                        </label>                             
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                      <label class="h6 mt-4 aluno_font_color">Termos</label>
                      <div class="fd-app-meus-cursos mt-0">
                        <div class="fd-app-curso-aulas-list mt-0">
                          <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                            <label class="switch">
                              <input
                                v-if="fastRequisitoInscricao.termo_concorda"
                                type="checkbox"
                                checked="checked"
                                @click="chkRequisito('termo_concorda', fastRequisitoInscricao.termo_concorda)"
                              >
                              <input
                                v-else
                                type="checkbox"
                                @click="chkRequisito('termo_concorda', fastRequisitoInscricao.termo_concorda)"
                              >
                              <span class="slider round" />
                            </label>                             
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <quill-editor
                        v-model="fastRequisitoInscricao.termo"
                        :options="editorOption"
                        @focus="fastRequisitoInscricao.alteracao_pendente = true"
                      />
                    </div>
                  </div>                    
                </div>
                  
                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                      <label class="h6 mt-4 aluno_font_color">Enviar email</label>
                      <div class="fd-app-meus-cursos mt-0">
                        <div class="fd-app-curso-aulas-list mt-0">
                          <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                            <label class="switch">
                              <input
                                v-if="fastRequisitoInscricao.enviar_email"
                                type="checkbox"
                                checked="checked"
                                @click="chkRequisito('enviar_email', fastRequisitoInscricao.enviar_email)"
                              >
                              <input
                                v-else
                                type="checkbox"
                                @click="chkRequisito('enviar_email', fastRequisitoInscricao.enviar_email)"
                              >
                              <span class="slider round" />
                            </label>                             
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <quill-editor
                        v-model="fastRequisitoInscricao.html_email_confirmacao"
                        :options="editorOption"
                        @focus="fastRequisitoInscricao.alteracao_pendente = true"
                      />
                    </div>
                  </div>                    
                </div>

                <div
                  v-if="fastRequisitoInscricao.alteracao_pendente"
                  class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                >
                  <button
                    class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                    @click="atualizaRequisitoInscricao()"
                  >
                    <small>
                      <i
                        class="fa fa-floppy-o"
                        aria-hidden="true"
                      />
                      &nbsp;Salvar alterações
                    </small>
                  </button>
                  <button
                    class="btn btn-secondary btn-sm mt-2 ml-2"
                    @click.prevent="getTrilhaIngressos()"
                  >
                    <small>
                      <i
                        class="fa fa-ban"
                        aria-hidden="true"
                      />
                      &nbsp;Cancelar
                    </small>
                  </button>
                </div>
              </div>
              <!-- /Requisito inscrição -->

              <!-- Requisito prova -->
              <div
                v-else-if="fastIngressoRequisito.etapa == 'PO' || fastIngressoRequisito.etapa == 'PD'"
                class="row"
              >
                <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                  <h4 class="aluno_font_color">
                    Questões da prova ({{ fastRequisitoProvaObjetiva.questoes.length }})
                  </h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 mt-4 text-right">
                  <button
                    class="btn btn-sm btn-primary"
                    @click="showModal('modalProvasPlataforma')"
                  >
                    <small>
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                      />
                      &nbsp;Selecionar prova
                    </small>
                  </button>
                </div>
                <div
                  v-if="fastRequisitoProvaObjetiva.questoes.length"
                  class="col-12"
                >
                  <div class="row p-4">
                    <div
                      class="col-12 pl-4 pr-4"
                      style="background-color: #66c7eb"
                    >
                      <div class="row pl-4 pr-4 pt-2 pb-4">
                        <carousel
                          :navigation-enabled="true"
                          :per-page="1"
                          :mouse-drag="false"
                          :adjustable-height="true"
                          :pagination-size="20"
                          :resistance-coef="40"
                          class="col-12 mt-4 shadow p-2 mb-2 bg-white rounded"
                        >
                          <slide
                            v-for="(q, index) in fastRequisitoProvaObjetiva.questoes"
                            :key="q.id_questao"
                          >
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
                                <h5>
                                  Questão {{ index + 1 }} de
                                  {{ fastRequisitoProvaObjetiva.questoes.length }}
                                </h5>
                              </div>
                              <div class="col-12 mt-2">
                                <div v-html="q.pergunta" />
                              </div>
                              <div
                                v-if="q.alternativas.length && fastIngressoRequisito.etapa == 'PO'"
                                class="col-12 p-4"
                              >
                                <div
                                  v-for="(a, index2) in q.alternativas"
                                  :key="a.id_alternativa"
                                  class="row pl-2 pr-2"
                                >
                                  <div :class="'col-12 alt-correta-' + a.correta">
                                    <div class="row">
                                      <div class="col-6 mt-2">
                                        <h6>Alternativa #{{ index2 + 1 }}</h6>
                                      </div>
                                      <div class="col-12">
                                        <div v-html="a.alternativa" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </slide>
                        </carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Requisito prova -->

              <!-- Requisito inscrição -->
              <div
                v-else-if="fastIngressoRequisito.etapa == 'CM'"
                class="row"
              >
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <quill-editor
                        v-model="fastRequisitoConfirmacao.html_email_confirmacao"
                        :options="editorOption"
                        @focus="fastRequisitoConfirmacao.alteracao_pendente = true"
                      />
                    </div>
                  </div>                    
                </div>

                <div
                  v-if="fastRequisitoConfirmacao.alteracao_pendente"
                  class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                >
                  <button
                    class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                    @click="atualizaRequisitoConfirmacao()"
                  >
                    <small>
                      <i
                        class="fa fa-floppy-o"
                        aria-hidden="true"
                      />
                      &nbsp;Salvar alterações
                    </small>
                  </button>
                  <button
                    class="btn btn-secondary btn-sm mt-2 ml-2"
                    @click.prevent="getTrilhaIngressos()"
                  >
                    <small>
                      <i
                        class="fa fa-ban"
                        aria-hidden="true"
                      />
                      &nbsp;Cancelar
                    </small>
                  </button>
                </div>
              </div>
              <!-- /Requisito inscrição -->

              <!-- Requisito documento -->
              <div
                v-else-if="fastIngressoRequisito.etapa == 'DC'"
                class="row"
              >
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <label class="h6 mt-4 aluno_font_color">Tipo de documento</label>
                      <button
                        class="btn btn-sm btn-primary ml-2 pt-0 pb-0"
                        @click="showModal('modalCriarTipoDocumento')"
                      >
                        <small>Criar novo</small>                
                      </button>
                      <div class="form-group">
                        <select
                          v-model="fastRequisitoDocumento.tipo_documento"
                          class="form-control"
                          @change="atualizaRequisitoDocumento()"
                        >
                          <option
                            v-for="documento in fastSecretariaTipoDocumentos"
                            :key="documento.tipo_documento"
                            :value="documento.tipo_documento"
                          >
                            {{ documento.nome_documento }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>                    
                </div>
              </div>
              <!-- /Requisito documento -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarRequisito"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Criar requisito</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarRequisito')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div class="row">
            <div
              v-if="fastIngressoRequisitoNovo.etapa != 'DC'"
              class="col-sm-12 col-md-12 col-lg-6"
            >
              <label>Descrição da atividade</label>
              <div class="form-group">
                <input
                  v-model="fastIngressoRequisitoNovo.descricao_atividade"
                  class="form-control"
                  type="text"
                  placeholder="Descrição da atividade"
                >
              </div>              
            </div>

            <div
              v-if="fastIngressoRequisitoNovo.etapa == 'DC'"
              class="col-sm-12 col-md-12 col-lg-6"
            >
              <label>Tipo de documento</label>
              <button
                class="btn btn-sm btn-primary ml-2 pt-0 pb-0"
                @click="showModal('modalCriarTipoDocumento', 'modalCriarRequisito')"
              >
                <small>Criar novo</small>                
              </button>
              <div class="form-group">
                <select
                  v-model="fastRequisitoDocumentoNovo.tipo_documento"
                  class="form-control"
                >
                  <option
                    v-for="documento in fastSecretariaTipoDocumentos"
                    :key="documento.tipo_documento"
                    :value="documento.tipo_documento"
                  >
                    {{ documento.nome_documento }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6">
              <label>Etapa</label>
              <div class="form-group">
                <select
                  v-model="fastIngressoRequisitoNovo.etapa"
                  class="form-control"
                  @change="verificaTipoRequisito()"
                >
                  <option value="IA">
                    Inscrição do aluno
                  </option>
                  <option value="PO">
                    Prova Objetiva
                  </option>
                  <option value="PD">
                    Prova Dissertativa
                  </option>
                  <option value="CD">
                    Correção Dissertativa
                  </option>
                  <option value="MT">
                    Matrícula
                  </option>
                  <option value="RA">
                    Geração do RA
                  </option>
                  <option value="GC">
                    Geração do Contrato
                  </option>
                  <option value="GB">
                    Geração de Boletos
                  </option>
                  <option value="CM">
                    Confirmação de Mátricula
                  </option>
                  <option value="LA">
                    Lista de aprovados
                  </option>
                  <option value="DC">
                    Documento
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <label>Data início</label>
              <div class="form-group">
                <input
                  v-model="fastIngressoRequisitoNovo.data_inicio"
                  class="form-control"
                  type="date"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <label>Data fim</label>
              <div class="form-group">
                <input
                  v-model="fastIngressoRequisitoNovo.data_fim"
                  class="form-control"
                  type="date"
                >
              </div>
            </div>
            <div class="col-12">                
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarRequisito()"
                >
                  <small>Criar requisito</small>                    
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirRequisito"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Exclui requisito?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirRequisito')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="excluirRequisito()"
                >
                  <small>Confirmar</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

     
    <modal
      name="modalProvasPlataforma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Provas da plataforma</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalProvasPlataforma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">
                      Id
                    </th>
                    <th scope="col">
                      Nome
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                    >
                      Questões
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfProvas.length">
                  <tr
                    v-for="prova in pageOfProvas"
                    :key="prova.id_prova"
                  >
                    <td>
                      #{{ prova.id_prova }}
                    </td>
                    <td>
                      {{ prova.nome_prova }}
                    </td>
                    <td class="text-center">
                      {{ prova.n_questoes }}
                    </td>
                    <td class="align-middle text-center">
                      <button
                        class="btn btn-sm btn-success btn-block"
                        title="Adicionar prova"
                        @click="adicionaProvaRequisito(prova.id_prova)"
                      >
                        <small> + Adicionar </small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhuma prova encontrada
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="provasFiltro"
                @changePage="pageOfProvas = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarTipoDocumento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo documento</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarTipoDocumento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Nome do documento</label>
              <input
                v-model="fastSecretariaTipoDocumentoNovo.nome_documento"
                type="text"
                class="form-control"
                maxlength="30"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarSecretariaTipoDocumento()"
                >
                  Criar documento
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import vueStep from "vue-step";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import Pagination from "../components/Pagination";
import { Carousel, Slide } from "vue-carousel"; //https://github.com/ssense/vue-carousel#readme

export default {
  name: "HomeInternoSecretariaIngressoRequisito",
  components: {
    vueStep,
    quillEditor,
    Pagination,
    Carousel,
    Slide
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",      
      fastIngressoRequisito: {
        id_trilha_ingresso: "",
        etapa: "IA",
        sequencia: "",
        descricao_atividade: "",
        data_inicio: "",
        data_fim: "",
        id_registro: "",
        alteracao_pendente: false
      },
      fastIngressoRequisitoNovo: {
        id_trilha_ingresso: "",
        etapa: "IA",
        sequencia: "",
        descricao_atividade: "",
        data_inicio: "",
        data_fim: "",
        alteracao_pendente: false
      },
      fastTrilhaIngresso: {
        alteracao_pendente: "",
        alteracao_sequencia_pendente: "",
        data_criacao: "",
        data_ultima_alteracao: "",
        descricao_trilha: "",
        id_plataforma: 0,
        id_trilha_ingresso: "",
        id_usuario_alterou: "",
        id_usuario_criou: "",
        requisitosCarregou: "",
        template: "",
        requisitos: []
      },
      // Inscrição do aluno
      fastRequisitoInscricao: {
        id_inscricao: 0,
        ano_conclusao: 0,
        utilizar_enem: 0,
        id_curso_opcao1: 0,
        id_curso_opcao2: 0,
        pcd: 0,
        termo_concorda: 0,
        termo: "",
        enviar_email: 0,
        id_registro: 0,
        html_email_confirmacao: "",
        alteracao_pendente: false
      },
      // Confirmação de matrícula
      fastRequisitoConfirmacao: {
        id_confirmacao: 0,
        enviar_email: 'S',
        html_email_confirmacao: '',
        id_registro: 0
      },
      // Prova objetiva
      fastRequisitoProvaObjetiva: {
        id_trilha_ingresso: 0,
        id_prova: 0,
        id_registro: 0,
        questoes: []
      },
      // Plugins steps
      nowStep: 0,
      stepList: [],
      // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "image"],
          ],
        },
      },
      // Lista de provas
      provasTotal: [],
      provasFiltro: [],
      pageOfProvas: [],
      // Documento
      fastSecretariaTipoDocumentos: [],
      fastRequisitoDocumento: {
        id_documentos: 0,
        tipo_documento: 0,
        id_registro: 0
      },
      fastRequisitoDocumentoNovo: {
        id_documentos: 0,
        tipo_documento: 0,
        id_registro: 0
      },
      fastSecretariaTipoDocumentoNovo: {
        tipo_documento: 0,
        nome_documento: "",
        id_plataforma: 0
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_trilha_ingresso) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Buscar ingressos
            this.getTrilhaIngressos();
            // Documentos
            this.getSecretariaTipoDocumentos(this.$route.params.id_plataforma);
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    getTrilhaIngressos() {
      this.promiseGetTrilhaIngressos(this.$route.params.id_plataforma).then(obj => {
        const ingresso = Array.from(obj).filter(e => e.id_trilha_ingresso == this.$route.params.id_trilha_ingresso);
        if (ingresso.length) {
          ingresso[0].alteracao_pendente = false;
          ingresso[0].alteracao_sequencia_pendente = false;
          ingresso[0].requisitos = [];
          ingresso[0].requisitosCarregou = false;
          this.getIngressoRequisitos(ingresso[0]);
          this.fastTrilhaIngresso = ingresso[0];
        }                
        this.$store.state.fastCarregando = false;
        //console.log("promiseGetTrilhaIngressos", this.fastTrilhaIngresso)
        }).catch((e) => {
        console.log("promiseGetTrilhaIngressos", e);
        this.exibeToasty("Nenhum ingresso configurado", "error");
        this.$store.state.fastCarregando = false;
      })
    },
    getIngressoRequisitos(ingresso) {      
      this.promiseGetFastApi("api/fast_secretaria_sequencia/lista", "id_trilha_ingresso=" + ingresso.id_trilha_ingresso).then(obj => {
        //console.log("getIngressoRequisitos", obj)
        Array.from(obj).forEach((e, index) => {
          if (this.$route.params.id_registro == e.id_registro) {
            e.alteracao_pendente = false;
            this.fastIngressoRequisito = e;
            this.nowStep = index + 1;
            if (e.etapa == "IA") this.getIngressoRequisitoIA(e.id_registro);
            else if (e.etapa == "PO" || e.etapa == "PD") {
              // Busca provas
              this.promiseGetFastApi("api/fast_plataforma_prova/lista_nquestoes", "id_plataforma=" + this.$route.params.id_plataforma).then(obj => {
                console.log("provas", obj)
                this.provasTotal = [];
                this.provasFiltro = [];
                if (e.etapa == "PO") {
                  Array.from(obj).forEach(p => {
                    if (p.tipo_prova != "D") {
                      this.provasTotal.push(p)
                      this.provasFiltro.push(p)
                    }
                  });
                } else {
                  Array.from(obj).forEach(p => {
                    if (p.tipo_prova == "D") {
                      this.provasTotal.push(p)
                      this.provasFiltro.push(p)
                    }
                  });
                }
                
              }).catch(e => { console.log(e) });
              this.getIngressoRequisitoPO(e.id_registro);
            } else if (e.etapa == "CM") {
              this.getIngressoRequisitoCM(e.id_registro);
            } 
          }
          if (e.data_inicio) {            
            e.data_inicio = e.data_inicio.split("T")[0];
          }

          if (e.data_fim) {
            e.data_fim = e.data_fim.split("T")[0];
          }
        });

        ingresso.requisitosCarregou = true
        ingresso.requisitos = obj;

        this.stepList = ingresso.requisitos.map((e) => {
          return e.sequencia + " - " + this.ajustaIngressoEtapa(e.etapa);
        });
        
      }).catch(e => {
        console.log(e);
      })
    },
    async promiseGetTrilhaIngressos(id_plataforma) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_tirlha_ingresso/lista?id_plataforma=" +
              id_plataforma,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          if (json.length) {
            resolve(json)
          } else {
            reject(false);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    getIngressoRequisitoIA(id_registro) {      
      this.promiseGetFastApi("api/fast_secretaria_sequencia_inscricao/lista", "id_registro=" + id_registro).then(obj => {
        console.log("getIngressoRequisitoIA", obj)
        obj.alteracao_pendente = false;
        if (obj.length) this.fastRequisitoInscricao = obj[0];
      }).catch(e => {
        console.log(e);
      })
    },
    getIngressoRequisitoPO(id_registro) {      
      this.promiseGetFastApi("api/fast_secretaria_sequencia_prova/lista", "id_registro=" + id_registro).then(obj => {
        console.log("getIngressoRequisitoPO", obj);
        const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
            return obj.find((a) => a.id_questao === id_questao);
          });

          questoesUnicas.forEach((a, index) => {
            obj.forEach((q, index2) => {
              if (a.id_questao == q.id_questao) {
                if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                questoesUnicas[index].alternativas.push(q);
              }
            });
          });

        obj.alteracao_pendente = false;
        if (obj.length) this.fastRequisitoProvaObjetiva.questoes = questoesUnicas;
      }).catch(e => {
        console.log(e);
      })
    },
    getIngressoRequisitoCM(id_registro) {      
      this.promiseGetFastApi("api/fast_secretaria_sequencia_confirmacao/lista", "id_registro=" + id_registro).then(obj => {
        console.log("getIngressoRequisitoCM", obj)
        obj.alteracao_pendente = false;
        if (obj.length) this.fastRequisitoConfirmacao = obj[0];
      }).catch(e => {
        console.log(e);
      })
    },
    chkRequisito(propriedade, value) {
      switch (propriedade) {
        case 'ano_conclusao':
          this.fastRequisitoInscricao.ano_conclusao = !this.fastRequisitoInscricao.ano_conclusao;
          break;   
        case 'utilizar_enem':
          this.fastRequisitoInscricao.utilizar_enem = !this.fastRequisitoInscricao.utilizar_enem;
          break;    
        case 'pcd':
          this.fastRequisitoInscricao.pcd = !this.fastRequisitoInscricao.pcd;
          break;
        case 'termo_concorda':
          this.fastRequisitoInscricao.termo_concorda = !this.fastRequisitoInscricao.termo_concorda;
          break;
        case 'enviar_email':
          this.fastRequisitoInscricao.enviar_email = !this.fastRequisitoInscricao.enviar_email;
          break;
        default:
          break;
      }

      const obj = {
        id_inscricao: this.fastRequisitoInscricao.id_inscricao,
        ano_conclusao: this.fastRequisitoInscricao.ano_conclusao,
        utilizar_enem: this.fastRequisitoInscricao.utilizar_enem,
        id_curso_opcao1: this.fastRequisitoInscricao.id_curso_opcao1,
        id_curso_opcao2: this.fastRequisitoInscricao.id_curso_opcao2,
        pcd: this.fastRequisitoInscricao.pcd,
        termo_concorda: this.fastRequisitoInscricao.termo_concorda,
        termo: this.fastRequisitoInscricao.termo,
        enviar_email: this.fastRequisitoInscricao.enviar_email,
        id_registro: this.fastRequisitoInscricao.id_registro,
        html_email_confirmacao: this.fastRequisitoInscricao.html_email_confirmacao
      }
      this.atualizaRequisitoIA(obj);
    },
    atualizaRequisitoIA(obj) {      
      this.$store.state.fastCarregando = true;
      this.promiseAtualizarFastApi(obj, "fast_secretaria_sequencia_inscricao").then(e => {
        this.exibeToasty("Salvo com sucesso", "success");
        this.$store.state.fastCarregando = false;
        // Buscar ingressos
        this.getTrilhaIngressos();
      }).catch(e => {
        this.exibeToasty("Erro ao salvar alterações", "error");
        this.$store.state.fastCarregando = false;
      });
    },
    atualizaRequisitoCM(obj) {      
      this.$store.state.fastCarregando = true;
      this.promiseAtualizarFastApi(obj, "fast_secretaria_sequencia_confirmacao").then(e => {
        this.exibeToasty("Salvo com sucesso", "success");
        this.$store.state.fastCarregando = false;
        // Buscar ingressos
        this.getTrilhaIngressos();
      }).catch(e => {
        this.exibeToasty("Erro ao salvar alterações", "error");
        this.$store.state.fastCarregando = false;
      });
    },
    async criarRequisito() {
      if (this.fastIngressoRequisitoNovo.etapa == 'DC') {
            this.fastIngressoRequisitoNovo.descricao_atividade = this.fastRequisitoDocumentoNovo.tipo_documento;
      }
      const fast_secretaria_sequencia = {
        id_trilha_ingresso: this.$route.params.id_trilha_ingresso, 
        etapa: this.fastIngressoRequisitoNovo.etapa,
        descricao_atividade: this.fastIngressoRequisitoNovo.descricao_atividade,
        data_inicio: this.fastIngressoRequisitoNovo.data_inicio,
        data_fim: this.fastIngressoRequisitoNovo.data_fim
      };
      this.promiseInserirFastApi(fast_secretaria_sequencia, "fast_secretaria_sequencia").then((res) => {
        if (res.length) {
            this.exibeToasty("Requisito criado com sucesso", "success");
            this.hideModal("modalCriarRequisito");
            // Buscar ingressos
            this.getTrilhaIngressos();
          
        } else {
          this.exibeToasty("Erro ao criar requisito", "error");
        }    
      }).catch((e) => {
        this.exibeToasty("Erro ao criar requisito", "error");
      });
    },
    corrigeSequenciaRequisito(ingresso, requisito) {
      ingresso.alteracao_sequencia_pendente = true;
      ingresso.requisitos.sort(this.reornedarArrayObjetos("sequencia"));
    },
   
    async atualizaRequisito() {
      const fast_secretaria_sequencia = {
        id_registro: this.fastIngressoRequisito.id_registro,
        id_trilha_ingresso: this.fastIngressoRequisito.id_trilha_ingresso,
        etapa: this.fastIngressoRequisito.etapa,        
        data_inicio: this.fastIngressoRequisito.data_inicio,
        data_fim: this.fastIngressoRequisito.data_fim,
        sequencia: this.fastIngressoRequisito.sequencia,
        descricao_atividade: this.fastIngressoRequisito.descricao_atividade,
      };

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_sequencia/atualiza",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(fast_secretaria_sequencia)
          )
        );

        let json = await resp.json();
        let obj = Array.from(json);
        this.exibeToasty("Salvo com sucesso", "success");
        this.$store.state.fastCarregando = false;
        this.fastIngressoRequisito.alteracao_pendente = false;
        
      } catch (e) {
        this.$store.state.fastCarregando = false;
        this.exibeToasty("Erro ao atualizar", "error");
        console.log("Erro", e);
      }
    },
    async atualizaRequisitoInscricao() {
      const obj = {
        id_inscricao: this.fastRequisitoInscricao.id_inscricao,
        ano_conclusao: this.fastRequisitoInscricao.ano_conclusao,
        utilizar_enem: this.fastRequisitoInscricao.utilizar_enem,
        id_curso_opcao1: this.fastRequisitoInscricao.id_curso_opcao1,
        id_curso_opcao2: this.fastRequisitoInscricao.id_curso_opcao2,
        pcd: this.fastRequisitoInscricao.pcd,
        termo_concorda: this.fastRequisitoInscricao.termo_concorda,
        termo: this.fastRequisitoInscricao.termo,
        enviar_email: this.fastRequisitoInscricao.enviar_email,
        id_registro: this.fastRequisitoInscricao.id_registro,
        html_email_confirmacao: this.fastRequisitoInscricao.html_email_confirmacao
      }
      this.atualizaRequisitoIA(obj);
    },
    async atualizaRequisitoConfirmacao() {
      const obj = {
        id_confirmacao: this.fastRequisitoConfirmacao.id_confirmacao,
        html_email_confirmacao: this.fastRequisitoConfirmacao.html_email_confirmacao,        
        id_registro: this.fastRequisitoConfirmacao.id_registro
      }
      this.atualizaRequisitoCM(obj);
    },
    async excluirRequisito(){
      const fast_secretaria_sequencia = {
        id_registro: this.fastIngressoRequisito.id_registro,
        id_plataforma: this.$route.params.id_plataforma
      };

      this.promiseExcluirFastApi(fast_secretaria_sequencia, "fast_secretaria_sequencia").then(e => {
        this.exibeToasty("Excluído com sucesso", "success");        
        if (this.fastTrilhaIngresso.requisitos.length > 1) {
          this.fastTrilhaIngresso.requisitos.forEach(r => {
            if (r.id_registro != this.fastIngressoRequisito.id_registro) {
              window.location.href = '/plataforma/' + this.$route.params.id_plataforma + '/secretaria/ingresso/' + this.$route.params.id_trilha_ingresso + '/requisito/' + r.id_registro;
            }
          });          
        } else {
          window.location.href = '/plataforma/' + this.$route.params.id_plataforma + '/secretaria/ingressos';
        }
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.$store.state.fastCarregando = false;
      });
    },
    ajustaIngressoEtapa(etapa) {
      switch (etapa) {
        case 'IA':
          return "Inscrição do aluno";
          break;
        case 'PO':
          return "Prova objetiva";
          break;
        case 'PD':
          return "Prova Dissertativa";
          break;
        case 'CD':
          return "Correção Dissertativa";
          break;
        case 'MT':
          return "Matrícula";
          break;
        case 'RA':
          return "Geração do RA";
          break;
        case 'GC':
          return "Geração do Contrato";
          break;
        case 'GB':
          return "Geração de Boletos";
          break;
        case 'CM':
          return "Confirmação de Mátricula";
          break;
        case 'LA':
          return "Lista de Aprovados";
          break;
        case 'DC':
          return "Documento";
          break;
        default:
          break;
      }
    },
    selecionaRequisitoStep(item) {
      let requisitos = this.fastTrilhaIngresso.requisitos.filter((e, index) => {
        if (item == index + 1) return e.id_registro;
      });
      if (requisitos.length) window.location.href = '/plataforma/' + this.$route.params.id_plataforma + '/secretaria/ingresso/' + this.$route.params.id_trilha_ingresso + '/requisito/' + requisitos[0].id_registro;
    },
    async adicionaProvaRequisito(id_prova) {
      const fast_secretaria_sequencia_prova = {
        id_prova: id_prova,
        id_registro: this.fastIngressoRequisito.id_registro,
        id_trilha_ingresso: this.fastIngressoRequisito.id_trilha_ingresso
      }
      this.$store.state.fastCarregando = true;
      this.promiseAtualizarFastApi(fast_secretaria_sequencia_prova, "fast_secretaria_sequencia_prova").then(e => {        
        this.exibeToasty("Salvo com sucesso", "success");
        this.$store.state.fastCarregando = false;
        this.hideModal('modalProvasPlataforma')
        // Buscar ingressos
        this.getTrilhaIngressos();
      }).catch(e => {
        this.exibeToasty("Erro ao salvar alterações", "error");
        this.$store.state.fastCarregando = false;
      });
    },
    getSecretariaTipoDocumentos(id_plataforma) {      
      this.promiseGetFastApi("api/fast_secretaria_tipo_documento/lista", "id_plataforma=" + id_plataforma).then(obj => {
        console.log("getSecretariaTipoDocumentos", obj)
        if (obj.length) {
          this.fastSecretariaTipoDocumentos = obj;          
          this.getRequisitoDocumento(this.$route.params.id_registro)
        }
      }).catch(e => {
        console.log(e);
      })
    },
    getRequisitoDocumento(id_registro) {      
      this.promiseGetFastApi("api/fast_secretaria_sequencia_documento/lista", "id_registro=" + id_registro).then(obj => {
        console.log("getRequisitoDocumento", obj)
        if (obj.length) this.fastRequisitoDocumento = obj[0]
      }).catch(e => {
        console.log(e);
      })
    },
    async criarSecretariaTipoDocumento() {
      const fast_secretaria_tipo_documento = {
        nome_documento: this.fastSecretariaTipoDocumentoNovo.nome_documento,
        id_plataforma: this.$route.params.id_plataforma
      };
      this.promiseInserirFastApi(fast_secretaria_tipo_documento, "fast_secretaria_tipo_documento").then((res) => {        
        this.exibeToasty("Documento criado com sucesso", "success");
        this.hideModal("modalCriarTipoDocumento");
        // Tipo documentos
        this.fastSecretariaTipoDocumentos = res;
      }).catch((e) => {
        this.exibeToasty("Erro ao criar requisito", "error");
      });
    },
    async atualizaRequisitoDocumento() {
      const obj = {
        id_documentos: this.fastRequisitoDocumento.id_documentos,
        tipo_documento: this.fastRequisitoDocumento.tipo_documento,
        id_registro: this.$route.params.id_registro
      };
      this.$store.state.fastCarregando = true;
      this.promiseAtualizarFastApi(obj, "fast_secretaria_sequencia_documento").then(e => {
        this.exibeToasty("Salvo com sucesso", "success");
        this.$store.state.fastCarregando = false;        
      }).catch(e => {
        this.exibeToasty("Erro ao salvar alterações", "error");
        this.$store.state.fastCarregando = false;
      });
    },
    verificaTipoRequisito() {
      if (this.fastIngressoRequisitoNovo.etapa == "DC") {        
        if (!this.fastSecretariaTipoDocumentos.length) {
          this.hideModal("modalCriarRequisito");
          this.showModal("modalCriarTipoDocumento");
        } else {
          this.fastRequisitoDocumentoNovo.tipo_documento = this.fastSecretariaTipoDocumentos[0].tipo_documento;
        }
      }
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}


.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

/** Alternativas */
.alt-correta-true {
  background-color: rgb(62, 255, 111);
}

/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
